import { createContext, useContext, useEffect, useState } from 'react'
import { getStorageItem, setStorageItem } from 'utils/localStorage'

import { BuildingProps } from 'libs/types'

const BUILDING_KEY = 'building'

interface BuildingContextData extends BuildingProps {
  saveBuilding: (building: BuildingProps) => void
}

export const BuildingContextDefaultValues = {
  name: '',
  id: 0,
  address: '',
  city: '',
  image: '',
  imageDescription: '',
  checkinTime: '',
  checkoutTime: '',
  mapsLink: '',
  uberLink: '',
  wazeLink: '',
  roomId: '',
  saveBuilding: () => null
}

export const BuildingContext = createContext<BuildingContextData>(
  BuildingContextDefaultValues
)

export type BuildingProviderProps = {
  children: React.ReactNode
}

const BuildingProvider = ({ children }: BuildingProviderProps) => {
  const [buildingInfo, setBuildingInfo] = useState<BuildingProps>(
    BuildingContextDefaultValues
  )

  useEffect(() => {
    const data = getStorageItem(BUILDING_KEY)

    if (data) {
      setBuildingInfo(data)
    }
  }, [])

  const saveBuilding = (building: BuildingProps) => {
    setBuildingInfo(building)
    setStorageItem(BUILDING_KEY, building)
  }

  return (
    <BuildingContext.Provider value={{ ...buildingInfo, saveBuilding }}>
      {children}
    </BuildingContext.Provider>
  )
}

const useBuilding = () => useContext(BuildingContext)

export { BuildingProvider, useBuilding }
