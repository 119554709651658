export default {
  title: 'Charlie · Check-in',
  description: 'Sistema de Check-in e acompanhamento de reservas do Charlie.',
  canonical: 'https://checkin.staycharlie.com.br',
  openGraph: {
    type: 'website',
    locale: 'pt_BR',
    url: 'https://checkin.staycharlie.com.br',
    site_name: 'Charlie · Check-in',
    images: [
      {
        url: 'https://checkin.staycharlie.com.br/img/cover.png',
        width: 1200,
        height: 630,
        alt: 'Charlie Check-in'
      }
    ]
  },
  twitter: {
    site: '@site',
    cardType: 'summary_large_image'
  }
}
