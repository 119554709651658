import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Image from 'next/image'

const BlipChatStates = {
  INITIAL: 'initial',
  ENTERED: 'entered',
  OPTIONS: 'options'
}

const BlipChatWithBubbles = () => {
  const [blipChat, setBlipChat] = useState<any>(null)
  const [chatState, setChatState] = useState<string>(BlipChatStates.INITIAL)
  const [showMobileBubble, setShowMobileBubble] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      const { BlipChat } = await require('blip-chat-widget')

      const chat = new BlipChat()
        .withAppKey(process.env.NEXT_PUBLIC_BLIP_CHAT)
        .withCustomCommonUrl('https://charlie.chat.blip.ai/')
        .withEventHandler(BlipChat.ENTER_EVENT, handleEnterEvent)
        .withEventHandler(BlipChat.LEAVE_EVENT, handleLeaveEvent)

      chat.build()

      setBlipChat(chat)
    })()
  }, [])

  const handleEnterEvent = () => {
    setChatState(BlipChatStates.ENTERED)
  }

  const handleLeaveEvent = () => {
    setChatState(BlipChatStates.INITIAL)
  }

  const handleChatIconClick = () => {
    if (chatState == BlipChatStates.INITIAL) {
      if (showMobileBubble) {
        setShowMobileBubble(false)
      }

      setChatState(BlipChatStates.OPTIONS)
    }

    if (chatState == BlipChatStates.ENTERED) {
      blipChat.toogleChat()
      setChatState(BlipChatStates.INITIAL)
    }
  }

  return (
    <>
      <div id="charlie-blip-chat" className={styles.chatGrid}>
        <div
          onClick={() => setChatState(BlipChatStates.INITIAL)}
          className={`${styles.mobileBackdrop} ${
            chatState == BlipChatStates.OPTIONS
              ? styles.showMobileBackdrop
              : styles.hideMobileBackdrop
          }`}
        ></div>
        <div className="flex justify-end sm:justify-normal">
          <div
            className={`${styles.bubbleContainer} ${
              chatState == BlipChatStates.ENTERED ||
              chatState == BlipChatStates.INITIAL
                ? styles.activeChatBubble
                : styles.inactiveChatBubble
            }`}
          >
            <span
              className={styles.bubbleCloseButton}
              onClick={() => setChatState(BlipChatStates.INITIAL)}
            >
              <Image src="/img/icons/x.svg" width="16" height="16" />
            </span>
            <p className="hidden sm:block">
              Olá, aqui é o Charlie, o seu concierge.
            </p>
            <p className="hidden sm:block font-bold mb-2">
              Como posso te ajudar?
            </p>
            <p className="hidden sm:block">
              Hi, I&apos;m Charlie, your concierge!
            </p>
            <p className="block sm:hidden font-bold">Posso ajudar?</p>
            <p className="sm:font-bold">How can I help you?</p>
          </div>
          <div
            className={`${styles.bubbleContainer} ${
              chatState == BlipChatStates.INITIAL
                ? styles.showInitialBubble
                : styles.hideInitialBubble
            }`}
          >
            <p className="font-bold">Como posso te ajudar?</p>
            <p>How can I help you?</p>
          </div>
        </div>
        <div className="flex sm:block flex-col items-end">
          {showMobileBubble && (
            <div className={`${styles.mobileInitialBubbleContainer}`}>
              <span
                className={styles.mobileCloseButton}
                onClick={() => setShowMobileBubble(false)}
              >
                <Image src="/img/icons/x.svg" width="16" height="16" />
              </span>
              <p className="block sm:hidden font-bold">Posso ajudar?</p>
              <p className="sm:font-bold">How can I help you?</p>
            </div>
          )}
          <button
            type="button"
            className={styles.chatButton}
            onClick={handleChatIconClick}
          >
            {chatState == BlipChatStates.ENTERED ? (
              <Image src="/img/icons/close.svg" width="25" height="25" />
            ) : (
              <Image src="/img/icons/hat.svg" width="25" height="25" />
            )}
          </button>
        </div>
        <div
          className={`${styles.chatOptions} ${
            chatState == BlipChatStates.ENTERED ||
            chatState == BlipChatStates.INITIAL
              ? styles.activeChatOptions
              : styles.inactiveChatOptions
          }`}
        >
          <a
            href="https://wa.me/5511940758022"
            target="_blank"
            className="flex items-center justify-center rounded-xl text-black bg-white mb-3 py-2 px-4 shadow-md no-underline"
            rel="noreferrer"
          >
            <div className="mr-2 w-[18px] h-[18px]">
              <Image src="/img/icons/wpp.svg" width="18" height="18" />
            </div>
            WhatsApp
          </a>

          <button
            type="button"
            className="flex items-center justify-center rounded-xl hover:text-black visited:text-black text-black bg-white mb-3 py-2 px-4 shadow-md"
            onClick={() => blipChat.toogleChat()}
          >
            <div className="mr-2 w-[18px] h-[18px]">
              <Image src="/img/icons/icon-chat.svg" width="18" height="18" />
            </div>
            Get support in English
          </button>
        </div>
      </div>
    </>
  )
}

export default BlipChatWithBubbles
