import { createContext, useContext, useEffect, useState } from 'react'
import { getStorageItem, setStorageItem } from 'utils/localStorage'

import { BookingProps } from 'libs/types'

const BOOKING_KEY = 'booking'

interface BookingContextData extends BookingProps {
  saveBooking: (booking: BookingProps) => void
}

export const BookingContextDefaultValues = {
  code: '',
  status: null,
  checkinDate: '',
  checkoutDate: '',
  checkinTime: '',
  checkoutTime: '',
  webCheckinCode: '',
  bookingId: '',
  adults: 0,
  children: 0,
  guestsTotal: 0,
  hasSecondGuest: false,
  saveBooking: () => null
}

export const BookingContext = createContext<BookingContextData>(
  BookingContextDefaultValues
)

export type BookingProviderProps = {
  children: React.ReactNode
}

const BookingProvider = ({ children }: BookingProviderProps) => {
  const [bookingInfo, setBookingInfo] = useState<BookingProps>(
    BookingContextDefaultValues
  )

  useEffect(() => {
    const data = getStorageItem(BOOKING_KEY)

    if (data) {
      setBookingInfo(data)
    }
  }, [])

  const saveBooking = (booking: BookingProps) => {
    setBookingInfo(booking)
    setStorageItem(BOOKING_KEY, booking)
  }

  return (
    <BookingContext.Provider value={{ ...bookingInfo, saveBooking }}>
      {children}
    </BookingContext.Provider>
  )
}

const useBooking = () => useContext(BookingContext)

export { BookingProvider, useBooking }
