import { createContext, useContext, useEffect, useState } from 'react'
import { getStorageItem, setStorageItem } from 'utils/localStorage'

import { GuestProps } from 'libs/types'

const GUEST_KEY = 'guest'

interface GuestContextData extends GuestProps {
  saveGuest: (guest: GuestProps) => void
}

export const GuestContextDefaultValues = {
  name: '',
  bookingGuests: [],
  saveGuest: () => null
}

export const GuestContext = createContext<GuestContextData>(
  GuestContextDefaultValues
)

export type GuestProviderProps = {
  children: React.ReactNode
}

const GuestProvider = ({ children }: GuestProviderProps) => {
  const [guestInfo, setGuestInfo] = useState<GuestProps>(
    GuestContextDefaultValues
  )

  useEffect(() => {
    const data = getStorageItem(GUEST_KEY)

    if (data) {
      setGuestInfo(data)
    }
  }, [])

  const saveGuest = (guest: GuestProps) => {
    setGuestInfo(guest)
    setStorageItem(GUEST_KEY, guest)
  }

  return (
    <GuestContext.Provider value={{ ...guestInfo, saveGuest }}>
      {children}
    </GuestContext.Provider>
  )
}

const useGuest = () => useContext(GuestContext)

export { GuestProvider, useGuest }
