import { useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'

import NextNprogress from 'nextjs-progressbar'
import { hotjar } from 'react-hotjar'
import * as gtag from 'libs/gtag'

import { DefaultSeo } from 'next-seo'
import SEO from '../../next-seo.config'

import WhatsApp from 'components/WhatsApp'

import { appWithTranslation } from 'next-i18next'
import { BookingProvider } from 'hooks/use-booking'
import { GuestProvider } from 'hooks/use-guest'
import { BuildingProvider } from 'hooks/use-building'

import 'styles/global.css'
import BlipChatWithBubbles from '../components/BlibChat'

function App({ Component, pageProps }: AppProps) {
  const [isMounted, setMounted] = useState(false)
  const router = useRouter()

  const HJID = Number(process.env.NEXT_PUBLIC_HOTJAR_HJID || '')
  const HJSV = Number(process.env.NEXT_PUBLIC_HOTJAR_HJSV || '')

  useEffect(() => {
    hotjar.initialize(HJID, HJSV)
  }, [HJID, HJSV])

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <BookingProvider>
      <BuildingProvider>
        <GuestProvider>
          <Head>
            <link rel="shortcut icon" href="/img/favicon.png" />
            <link rel="apple-touch-icon" href="/img/icon-512.png" />
            <link rel="manifest" href="/manifest.json" />
            <meta name="theme-color" content="#000000" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1"
            />
          </Head>
          <DefaultSeo {...SEO} />

          <NextNprogress
            color="#1e9761"
            startPosition={0.3}
            stopDelayMs={200}
            height={3}
          />

          <Component {...pageProps} />

          {isMounted && <BlipChatWithBubbles />}
        </GuestProvider>
      </BuildingProvider>
    </BookingProvider>
  )
}

export default appWithTranslation(App)
